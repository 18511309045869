import React, { useEffect } from 'react';
import { motion } from 'motion/react';

type ToastProps = {
  body: string;
  onHide: () => void;
};

const Toast = ({ body, onHide }: ToastProps) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onHide();
    }, 4000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <motion.div
      className="fixed z-50 h-fit rounded-2xl bg-gray-500 px-5 py-4 backdrop-blur-2xl bottom-safe-offset-3 right-safe-offset-3 max-md:left-safe-offset-3 md:max-w-[40vw] md:bottom-safe-offset-9 md:right-safe-offset-9"
      initial={{ x: '110%' }}
      animate={{ x: 0 }}
      exit={{ x: '110%' }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
    >
      <span data-cy="toast" className="font-inter text-lg">
        {body}
      </span>
    </motion.div>
  );
};

export default Toast;
